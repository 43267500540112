





























































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import clientVoucherStoreModule from "@/store/modules/clientVoucher";

const { mapActions: clientVoucherActions, mapGetters: clientVoucherGetters } =
  createNamespacedHelpers("CLIENT_VOUCHER_LIST");

export default Vue.extend<any, any, any, any>({
  name: "VoucherList",
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    voucher: undefined,
    showDialog: false,
    headers: [
      {
        text: "Voucher name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Date", value: "date" },
      { text: "Type", value: "type" },
      { text: "Value", value: "value" },
      // { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  watch: {
    options: {
      handler() {
        this.fetchVouchers();
      },
      deep: true,
    },
  },
  created() {
    this.fetchVouchers();
  },
  computed: {
    ...clientVoucherGetters(["clientVoucherPage"]),
  },
  methods: {
    ...clientVoucherActions([
      "fetchClientVoucherList",
      "updateClientVoucher",
      "deleteClientVoucher",
    ]),
    fetchVouchers() {
      this.fetchClientVoucherList(
        `?clientId=${this.clientId}&page=${this.options.page || 1}`
      );
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_VOUCHER_LIST")) {
      this.$store.registerModule(
        "CLIENT_VOUCHER_LIST",
        clientVoucherStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_VOUCHER_LIST");
  },
});
