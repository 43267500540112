



































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import clientMembershipStoreModule from "@/store/modules/clientMembership";
import membershipStoreModule from "@/store/modules/membership";
import { Business, Membership, Role } from "@/types";
import { DATE_REGEX } from "@/util/constants";

const { mapActions: clientMembershipActions } =
  createNamespacedHelpers("MEMBERSHIP_FORM");

const { mapActions: membershipActions, mapGetters: membershipGetters } =
  createNamespacedHelpers("MEMBERSHIPS");
type PriceKey =
  | "weekly"
  | "fortnightly"
  | "monthly"
  | "annually"
  | "biannually"
  | "lumpsum";
export default Vue.extend<any, any, any, any>({
  name: "MembershipForm",
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    membershipId: "",
    priceFrequency: "",
    subscribedOn: "",
    expiresOn: "",
    priceFrequencies: [
      { label: "Weekly", value: "weekly" },
      { label: "Fortnightly", value: "fortnightly" },
      { label: "Monthly", value: "monthly" },
      { label: "Annually", value: "annually" },
      { label: "Biannually", value: "biannually" },
      { label: "Lumpsum", value: "lumpsum" },
    ],
    membershipRules: [(v: string) => !!v || "Membership field is required"],
    priceFrequencyRules: [
      (v: string) => !!v || "Price frequency field is required",
    ],
    subscribedOnRules: [
      (v: string) => !!v || "Subscribed on field is required",
      (v: string) =>
        DATE_REGEX.test(v) || "Subscribed on field must be valid format",
    ],
    expiresOnRules: [
      (v: string) => !!v || "Expiry field is required",
      (v: string) => DATE_REGEX.test(v) || "Expiry field must be valid format",
    ],
    currency: "KES",
  }),
  computed: {
    ...membershipGetters(["membershipPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    membershipId() {
      if (this.membershipId) {
        const membership = this.membershipPage.docs.find(
          (m: Membership) => m._id === this.membershipId
        ) as Membership;

        this.priceFrequencies = Object.keys(membership.price).map(
          (k: PriceKey | string) => ({
            label: `${k}(${this.currency} ${membership.price[k as PriceKey]})`,
            value: `${k}`,
          })
        );
      }
    },
  },
  created() {
    this.resetForm();
    this.fetchData();
  },
  methods: {
    ...clientMembershipActions(["createClientMembership"]),
    ...membershipActions(["fetchMembershipList"]),
    searchMembership(q: string) {
      const bid = (this.role.business as Business)._id;
      if (q) this.fetchMembershipList(`?businessId=${bid}&q=${q}`); //TODO: use debounce
    },
    validateForm() {
      const valid = (
        this.$refs.programForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      let subscribedOn: string | string[] = this.subscribedOn;

      subscribedOn = (subscribedOn as string).split("/");

      subscribedOn = `${subscribedOn[1]}-${subscribedOn[0]}-${subscribedOn[2]}`;

      let expiresOn: string | string[] = this.expiresOn;

      expiresOn = (expiresOn as string).split("/");

      expiresOn = `${expiresOn[1]}-${expiresOn[0]}-${expiresOn[2]}`;
      this.createClientMembership({
        clientId: this.clientId,
        membershipId: this.membershipId,
        subscribedOn: subscribedOn,
        expiresOn: expiresOn,
        priceFrequency: this.priceFrequency,
      }).then((cm) => {
        if (cm) {
          this.$emit("data-saved", cm);
        }
      });
    },
    resetForm() {
      this.membershipId = "";
      this.priceFrequency = "";
      this.subscribedOn = "";
      this.expiresOn = "";
      (
        this.$refs.programForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    fetchData() {
      if (this.role) {
        this.fetchMembershipList(
          `?businessId=${(this.role.business as Business)._id}`
        );
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("MEMBERSHIP_FORM")) {
      this.$store.registerModule(
        "MEMBERSHIP_FORM",
        clientMembershipStoreModule
      );
    }
    if (!this.$store.hasModule("MEMBERSHIPS")) {
      this.$store.registerModule("MEMBERSHIPS", membershipStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("MEMBERSHIP_FORM");
    this.$store.unregisterModule("MEMBERSHIPS");
  },
});
