import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { ProductSale, Page } from "@/types";

type ProductSaleState = {
  productSalePage: Page<ProductSale>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productSaleAggregate: { [key: string]: any }[];
};

const productSale: Module<ProductSaleState, unknown> = {
  namespaced: true,
  state: () => ({
    productSalePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    productSaleAggregate: [],
  }),
  getters: {
    getProductSale: (state) => (productSaleId: string) =>
      state.productSalePage.docs.find((s) => s._id === productSaleId),
    productSalePage: (state) => state.productSalePage,
    productSaleAggregate: (state) => state.productSaleAggregate,
  },
  mutations: {
    ADD_PRODUCT_SALE: (state, _productSale) => {
      let idx = -1;
      state.productSalePage.docs.map((a, i) => {
        if (a._id === _productSale._id) idx = i;
      });
      if (idx === -1) state.productSalePage.docs.push(_productSale);
      else Vue.set(state.productSalePage.docs, idx, _productSale);
    },
    SET_PRODUCT_SALE_PAGE: (state, list) => {
      state.productSalePage = list;
    },
    REMOVE_PRODUCT_SALE(state, productSale) {
      let idx = -1;
      state.productSalePage.docs.map((a, i) => {
        if (a._id === productSale._id) idx = i;
      });
      if (idx > -1) state.productSalePage.docs.splice(idx, 1);
    },
    SET_PRODUCT_SALE_AGGREGATE(state, aggregate) {
      state.productSaleAggregate = aggregate;
    },
  },
  actions: {
    fetchProductSale(context, params = "") {
      api
        .get(`/v1/sale/product${params}`)
        .then((response) => {
          context.commit("ADD_PRODUCT_SALE", response.data.productSale);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchProductSaleList(context, params = "") {
      return await api
        .get(`/v1/sale/product${params}`)
        .then((response) => {
          context.commit(
            "SET_PRODUCT_SALE_PAGE",
            response.data.productSalePage
          );
          return response.data.productSalePage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createProductSale(context, payload) {
      return await api
        .post(`/v1/sale/product`, payload)
        .then((response) => {
          context.commit("ADD_PRODUCT_SALE", response.data.productSale);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ProductSale created",
            },
            { root: true }
          );
          return response.data.productSale;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateProductSale(context, data: { id: string; productSale: ProductSale }) {
      api
        .put(`/v1/sale/product/${data.id}`, data.productSale)
        .then((response) => {
          context.commit("ADD_PRODUCT_SALE", response.data.productSale);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ProductSale updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteProductSale(context, id) {
      api
        .delete(`/v1/sale/product${id}`)
        .then((response) => {
          context.commit("REMOVE_PRODUCT_SALE", response.data.productSale);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ProductSale deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchProductSaleAggregate(context, params = "") {
      return await api
        .get(`/v1/sale/product${params}`)
        .then((response) => {
          context.commit(
            "SET_PRODUCT_SALE_AGGREGATE",
            response.data.productSaleAggregate
          );
          return response.data.productSaleAggregate;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default productSale;
