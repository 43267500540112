import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { ClientVoucher, Page } from "@/types";

type ClientVoucherState = {
  clientVoucherPage: Page<ClientVoucher>;
};

const clientVoucher: Module<ClientVoucherState, unknown> = {
  namespaced: true,
  state: () => ({
    clientVoucherPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getClientVoucher: (state) => (clientVoucherId: string) =>
      state.clientVoucherPage.docs.find((c) => c._id === clientVoucherId),
    clientVoucherPage: (state) => state.clientVoucherPage,
  },
  mutations: {
    ADD_CLIENT_VOUCHER: (state, _clientVoucher) => {
      let idx = -1;
      state.clientVoucherPage.docs.map((r, i) => {
        if (r._id === _clientVoucher._id) idx = i;
      });
      if (idx === -1) state.clientVoucherPage.docs.push(_clientVoucher);
      else Vue.set(state.clientVoucherPage.docs, idx, _clientVoucher);
    },
    SET_CLIENT_VOUCHER_PAGE: (state, list) => {
      state.clientVoucherPage = list;
    },
    REMOVE_CLIENT_VOUCHER(state, clientVoucher) {
      let idx = -1;
      state.clientVoucherPage.docs.map((r, i) => {
        if (r._id === clientVoucher._id) idx = i;
      });
      if (idx > -1) state.clientVoucherPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchClientVoucher(context, params = "") {
      api
        .get(`/v1/voucher/client${params}`)
        .then((response) => {
          context.commit("ADD_CLIENT_VOUCHER", response.data.clientVoucher);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchClientVoucherList(context, params = "") {
      api
        .get(`/v1/voucher/client${params}`)
        .then((response) => {
          context.commit(
            "SET_CLIENT_VOUCHER_PAGE",
            response.data.clientVoucherPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createClientVoucher(context, payload) {
      return await api
        .post(`/v1/voucher/client`, payload)
        .then((response) => {
          context.commit("ADD_CLIENT_VOUCHER", response.data.clientVoucher);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client Voucher created",
            },
            { root: true }
          );
          return response.data.clientVoucher;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateClientVoucher(
      context,
      data: { id: string; clientVoucher: ClientVoucher }
    ) {
      api
        .put(`/v1/voucher/client/${data.id}`, data.clientVoucher)
        .then((response) => {
          context.commit("ADD_CLIENT_VOUCHER", response.data.clientVoucher);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client Voucher updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteClientVoucher(context, id) {
      api
        .delete(`/v1/voucher/client${id}`)
        .then((response) => {
          context.commit("REMOVE_CLIENT_VOUCHER", response.data.clientVoucher);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client Voucher deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default clientVoucher;
