













































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import TextField from "@/components/formbuilder/TextField.vue";
import TextAreaField from "@/components/formbuilder/TextAreaField.vue";
import SelectField from "@/components/formbuilder/SelectField.vue";
import { ConsultationFormValues, FormField } from "@/types";
import formStoreModule from "@/store/modules/consultationFormValues";

const { mapActions: formValuesActions } =
  createNamespacedHelpers("FORM_VALUES");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: {
    TextField,
    TextAreaField,
    SelectField,
  },
  name: "ViewConsultationForm",
  props: {
    clientConsultationFormId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    apiUrl: `${VUE_APP_API_URL}/v1/file`,
    valid: false,
    formFields: [] as FormField[],
  }),
  computed: {
    form(): ConsultationFormValues {
      return this.$store.getters["FORM_VALUES/getConsultationFormValues"](
        this.clientConsultationFormId
      );
    },
  },
  created() {
    this.fetchConsultationFormValues(
      `?consultationFormId=${this.clientConsultationFormId}`
    ).then((form: ConsultationFormValues) => {
      if (form) {
        form.consultationForm.form.formFields.map((field) => {
          this.formFields.push({
            name: field.name,
            value: form.formValues.find((fv) => fv.fieldId === field._id)
              ?.value,
            settings: field.settings,
            options: field.options,
            multiple: field.multiple,
          });
        });
      }
    });
  },
  methods: {
    ...formValuesActions(["fetchConsultationFormValues"]),
  },
  beforeCreate() {
    if (!this.$store.hasModule("FORM_VALUES")) {
      this.$store.registerModule("FORM_VALUES", formStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("FORM_VALUES");
  },
});
