import { ClientDocument, Page } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

const actions = {
  add_document: "ADD_DOCUMENT",
  set_document_list: "SET_DOCUMENT_LIST",
};

type ClientDocumentState = {
  clientDocumentPage: Page<ClientDocument>;
};

const clientDocument: Module<ClientDocumentState, unknown> = {
  namespaced: true,
  state: () => ({
    clientDocumentPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    clientDocumentPage: (state) => state.clientDocumentPage,
  },
  mutations: {
    [actions.add_document]: (state, _document) => {
      const isAvailable =
        state.clientDocumentPage.docs.filter(
          (item) => item._id == _document._id
        ).length > 0;

      if (isAvailable) {
        state.clientDocumentPage.docs = state.clientDocumentPage.docs.map(
          (item) => {
            return item._id == _document._id ? _document : item;
          }
        );
      } else {
        state.clientDocumentPage.docs.push(_document);
      }
    },
    [actions.set_document_list]: (state, _documents) => {
      state.clientDocumentPage = _documents;
    },
  },
  actions: {
    async createDocument(context, payload) {
      return await api
        .post("/v1/client-document", payload)
        .then((response) => {
          context.commit(actions.add_document, response.data.document);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client Document saved",
            },
            { root: true }
          );
          return response.data.document;
        })
        .catch(({ response }) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchDocumentList(context, params = "") {
      return await api
        .get(`/v1/client-document${params}`)
        .then((response) => {
          context.commit(actions.set_document_list, response.data.documentPage);
          return response.data;
        })
        .catch(({ response }) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default clientDocument;
