import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { ClientReward, Page } from "@/types";

type ClientRewardState = {
  clientRewardPage: Page<ClientReward>;
};

const clientReward: Module<ClientRewardState, unknown> = {
  namespaced: true,
  state: () => ({
    clientRewardPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getClientReward: (state) => (clientRewardId: string) =>
      state.clientRewardPage.docs.find((c) => c._id === clientRewardId),
    clientRewardPage: (state) => state.clientRewardPage,
  },
  mutations: {
    ADD_CLIENT_REWARD: (state, _clientReward) => {
      let idx = -1;
      state.clientRewardPage.docs.map((r, i) => {
        if (r._id === _clientReward._id) idx = i;
      });
      if (idx === -1) state.clientRewardPage.docs.push(_clientReward);
      else Vue.set(state.clientRewardPage.docs, idx, _clientReward);
    },
    SET_CLIENT_REWARD_PAGE: (state, list) => {
      state.clientRewardPage = list;
    },
    REMOVE_CLIENT_REWARD(state, clientReward) {
      let idx = -1;
      state.clientRewardPage.docs.map((r, i) => {
        if (r._id === clientReward._id) idx = i;
      });
      if (idx > -1) state.clientRewardPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchClientReward(context, params = "") {
      api
        .get(`/v1/client-reward${params}`)
        .then((response) => {
          context.commit("ADD_CLIENT_REWARD", response.data.clientReward);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchClientRewardList(context, params = "") {
      api
        .get(`/v1/client-reward${params}`)
        .then((response) => {
          context.commit(
            "SET_CLIENT_REWARD_PAGE",
            response.data.clientRewardPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createClientReward(context, payload) {
      return await api
        .post(`/v1/client-reward`, payload)
        .then((response) => {
          context.commit("ADD_CLIENT_REWARD", response.data.clientReward);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ClientReward created",
            },
            { root: true }
          );
          return response.data.clientReward;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateClientReward(
      context,
      data: { id: string; clientReward: ClientReward }
    ) {
      api
        .put(`/v1/client-reward/${data.id}`, data.clientReward)
        .then((response) => {
          context.commit("ADD_CLIENT_REWARD", response.data.clientReward);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ClientReward updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteClientReward(context, id) {
      api
        .delete(`/v1/client-reward${id}`)
        .then((response) => {
          context.commit("REMOVE_CLIENT_REWARD", response.data.clientReward);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ClientReward deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default clientReward;
