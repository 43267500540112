










































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import clientMembershipStoreModule from "@/store/modules/clientMembership";
import MembershipForm from "@/components/client/MembershipForm.vue";

const {
  mapActions: clientMembershipActions,
  mapGetters: clientMembershipGetters,
} = createNamespacedHelpers("CLIENT_MEMBERSHIP_LIST");

export default Vue.extend<any, any, any, any>({
  components: { MembershipForm },
  name: "MembershipList",
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    membership: undefined,
    showDialog: false,
    headers: [
      {
        text: "Membership name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Price", value: "price" },
      { text: "Status", value: "status" },
      { text: "Subscribed On", value: "subscribedOn" },
      { text: "Expires On", value: "expiresOn" },
      // { text: "Action", value: "action" },
    ],
  }),
  created() {
    this.fetchList();
  },
  computed: {
    ...clientMembershipGetters(["clientMembershipPage"]),
  },
  methods: {
    ...clientMembershipActions([
      "fetchClientMembershipList",
      "updateClientMembership",
      "deleteClientMembership",
    ]),
    closeModal() {
      this.showDialog = false;
      this.fetchList();
    },
    fetchList() {
      const id = this.clientId;
      this.fetchClientMembershipList(`?clientId=${id}&status=&paymentStatus=`);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_MEMBERSHIP_LIST")) {
      this.$store.registerModule(
        "CLIENT_MEMBERSHIP_LIST",
        clientMembershipStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_MEMBERSHIP_LIST");
  },
});
