var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.showDialog)?_c('membership-form',{attrs:{"clientId":_vm.clientId},on:{"cancel":function($event){_vm.showDialog = false},"data-saved":_vm.closeModal}}):_vm._e()],1),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-btn',{on:{"click":function($event){_vm.showDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Membership ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clientMembershipPage.docs,"no-data-text":"No memberships to display"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.membership.name)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" "+_vm._s(item.membership.price[item.price.frequency])+" ")]}},{key:"item.subscribedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.subscribedOn,"D/MM/YYYY"))+" ")]}},{key:"item.expiresOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.expiresOn,"D/MM/YYYY"))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }