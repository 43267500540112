





































































































































import Vue, { PropType } from "vue";

import { Client } from "@/types";
import clientConsultationStoreModule from "@/store/modules/clientConsultationForm";
import formBuilderStoreModule from "@/store/modules/form";
import { createNamespacedHelpers } from "vuex";
import ViewConsultationForm from "./ViewConsultationForm.vue";

const {
  mapActions: consultationFormActions,
  mapGetters: consultationFormGetters,
} = createNamespacedHelpers("CONSULTATIONFORMS");

const { mapGetters: formBuilderGetters, mapActions: formBuilderActions } =
  createNamespacedHelpers("FORMBUILDER");

export default Vue.extend<any, any, any, any>({
  components: { ViewConsultationForm },
  name: "ConsultationForm",
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true,
    },
  },
  created() {
    this.fetchConsultationForms();
    this.fetchForms();
  },
  data: () => ({
    dialog: false,
    consultationForm: {
      formId: undefined,
      notes: "",
    },
    editConsultationDialog: false,
    editedItem: {
      form: null,
      notes: null,
      client: null,
    },
    deleteDialog: false,
    form: undefined,
    headers: [
      {
        text: "Form Name",
        align: "start",
        sortable: true,
        value: "form.name",
      },
      {
        text: "Notes",
        value: "notes",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Action",
        value: "actions",
      },
    ],
    options: {} as { page: number },
  }),
  methods: {
    ...consultationFormActions([
      "fetchClientConsultationList",
      "createClientConsultationForm",
      "deleteClientConsultationForm",
    ]),
    ...formBuilderActions(["fetchFormList"]),
    fetchConsultationForms() {
      const params = `?clientId=${this.client?._id}&page=${
        this.options.page || 1
      }`;
      this.fetchClientConsultationList(params);
    },
    fetchForms() {
      if (this.$store.getters.role) {
        const bid = this.$store.getters.role.business?._id;
        const params = `?businessId=${bid}`;
        this.fetchFormList(params);
      }
    },
    close() {
      this.dialog = false;
    },
    save() {
      const payload = {
        ...this.consultationForm,
        clientId: this.client?._id,
      };
      this.createClientConsultationForm(payload).then(() => {
        this.close();
        this.consultationForm.formId = undefined;
        this.consultationForm.notes = "";
      });
    },
    closeViewItem() {
      this.editConsultationDialog = false;
    },
  },
  computed: {
    ...consultationFormGetters(["clientConsultationPage"]),
    ...formBuilderGetters(["formPage"]),
  },
  beforeCreate() {
    if (!this.$store.hasModule("CONSULTATIONFORMS")) {
      this.$store.registerModule(
        "CONSULTATIONFORMS",
        clientConsultationStoreModule
      );
    }
    if (!this.$store.hasModule("FORMBUILDER")) {
      this.$store.registerModule("FORMBUILDER", formBuilderStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CONSULTATIONFORMS");
    this.$store.unregisterModule("FORMBUILDER");
  },
});
