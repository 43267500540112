








































































































import { EmployeePermission } from "@/types";
import Vue from "vue";
import ClientEmailForm from "./ClientEmailForm.vue";
import UpdateClientForm from "./UpdateClientForm.vue";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;
export default Vue.extend<any, any, any, any>({
  components: { UpdateClientForm, ClientEmailForm },
  name: "ClientProfile",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showDialog: false,
    addEmailDialog: false,
    apiUrl: VUE_APP_API_URL,
    canEdit: false,
  }),
  computed: {
    permission(): EmployeePermission {
      return this.$store.getters.permission;
    },
  },
  watch: {
    permission: "checkPermission",
  },
  methods: {
    closeModal() {
      this.$emit("fetch-client");
      this.showDialog = false;
    },
    checkPermission() {
      if (this.permission) {
        this.canEdit = !!this.permission.permissionGroup.permissions.find(
          (p) => {
            if (p.section == "clients") {
              return !!p.acl.find(
                (acl) =>
                  acl.name == "Profile" && (acl.low || acl.owner || acl.medium)
              );
            }
            return false;
          }
        );
      }
    },
  },
});
