


























































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import clientRewardStoreModule from "@/store/modules/clientReward";

const { mapActions: clientRewardActions, mapGetters: clientRewardGetters } =
  createNamespacedHelpers("CLIENT_REWARD_LIST");
export default Vue.extend<any, any, any, any>({
  name: "RewardList",
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    reward: undefined,
    showDialog: false,
    headers: [
      {
        text: "Reward name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Date", value: "date" },
      { text: "Price", value: "price" },
      { text: "Earned points", value: "points" },
      // { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  watch: {
    options: {
      handler() {
        this.fetchRewards();
      },
      deep: true,
    },
  },
  created() {
    this.fetchRewards();
  },
  computed: {
    ...clientRewardGetters(["clientRewardPage"]),
  },
  methods: {
    ...clientRewardActions([
      "fetchClientRewardList",
      "updateClientReward",
      "deleteClientReward",
    ]),
    fetchRewards() {
      this.fetchClientRewardList(
        `?clientId=${this.clientId}&page=${this.options.page || 1}`
      );
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_REWARD_LIST")) {
      this.$store.registerModule("CLIENT_REWARD_LIST", clientRewardStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_REWARD_LIST");
  },
});
