
























































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import AppointmentList from "@/components/client/AppointmentList.vue";
// import Chat from "@/components/client/Chat.vue";
import ClientProfile from "@/components/client/ClientProfile.vue";
import GiftVouchers from "@/components/client/GiftVouchers.vue";
import MembershipProgram from "@/components/client/MembershipProgram.vue";
import ProductList from "@/components/client/ProductList.vue";
import RewardProgram from "@/components/client/RewardProgram.vue";
// import GallaryList from "@/components/client/GallaryList.vue";
import ConsultationFormList from "@/components/client/ConsultationFormList.vue";
import DocumentList from "@/components/client/DocumentList.vue";

import clientStoreModule from "@/store/modules/client";
import { Client } from "@/types";
import ActionButtons from "@/components/ActionButtons.vue";
import ClientEmails from "@/components/client/ClientEmails.vue";
import ClientSmsList from "@/components/client/ClientSMSList.vue";

const { mapActions: clientActions } = createNamespacedHelpers("CLIENT");

export default Vue.extend({
  components: {
    AppointmentList,
    ProductList,
    RewardProgram,
    MembershipProgram,
    GiftVouchers,
    // Chat,
    ClientProfile,
    ActionButtons,
    // GallaryList,
    ConsultationFormList,
    ClientEmails,
    DocumentList,
    ClientSmsList,
  },
  name: "Client",
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tab: 0,
    chatTab: 0,
    view: "Appointments",
    windowWidth: window.innerWidth,
    panel: 0,
    views: [
      "Appointments",
      "Products",
      "Reward Program",
      "Membership Program",
      "Gift Vouchers",
      "Messages & Email",
      "Consultation Form",
      "Documents",
      "Gallery",
    ],
  }),
  computed: {
    client(): Client {
      return this.$store.getters["CLIENT/getClient"](this.clientId);
    },
    width(): number {
      return window.innerWidth;
    },
  },
  created() {
    this.fetchClient(`?clientId=${this.clientId}`);
  },
  methods: {
    ...clientActions(["fetchClient"]),
    goback() {
      this.$router.go(-1);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT")) {
      this.$store.registerModule("CLIENT", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT");
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
});
