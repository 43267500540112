









































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import productSaleStoreModule from "@/store/modules/productSale";

const { mapActions: productSaleActions, mapGetters: productSaleGetters } =
  createNamespacedHelpers("PRODUCT_SALE_LIST");

export default Vue.extend<any, any, any, any>({
  name: "ProductList",
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    product: undefined,
    showDialog: false,
    headers: [
      {
        text: "Product name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Date", value: "date" },
      { text: "No. of items", value: "quantity" },
      { text: "Price", value: "price" },
      { text: "Payment Status", value: "paymentStatus" },
      // { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  watch: {
    options: {
      handler() {
        this.fetchProductSales();
      },
      deep: true,
    },
  },
  created() {
    this.fetchProductSales();
  },
  computed: {
    ...productSaleGetters(["productSalePage"]),
  },
  methods: {
    ...productSaleActions([
      "fetchProductSaleList",
      "updateProductSale",
      "deleteProductSale",
    ]),
    fetchProductSales() {
      this.fetchProductSaleList(
        `?clientId=${this.clientId}&page=${this.options.page || 1}`
      );
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PRODUCT_SALE_LIST")) {
      this.$store.registerModule("PRODUCT_SALE_LIST", productSaleStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PRODUCT_SALE_LIST");
  },
});
