var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clientVoucherPage.docs,"options":_vm.options,"server-items-length":_vm.clientVoucherPage.total,"no-data-text":"No gift vouchers to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.voucher.name)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.date,"D/MM/YYYY"))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.voucher.type)+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" "+_vm._s(item.value)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }