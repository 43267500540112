































































import { Client } from "@/types";
import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";
import ClientDocumentModule from "@/store/modules/clientDocument";

const { mapGetters: documentGetters, mapActions: documentActions } =
  createNamespacedHelpers("CLIENT_DOCUMENT");
const rules = {
  name: [(v: any) => !!v || "Document Name is required"],
  document: [
    (value: File) => !!value || "Document is required",
    (value: File) =>
      !value || value.type == "application/pdf" || "Only PDF files allowed",
  ],
};

export default Vue.extend<any, any, any, any>({
  name: "ClientDocumentList",
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true,
    },
  },
  data: () => ({
    isValid: false,
    rules: rules,
    headers: [
      {
        text: "Document",
        value: "document",
      },
      {
        text: "Document Name",
        sortable: false,
        align: "start",
        value: "name",
      },
      {
        text: "",
        value: "actions",
      },
    ],
    isNewDocument: false,
    documentForm: {
      name: "",
      file: [] as File[],
    },
  }),
  computed: {
    ...documentGetters(["clientDocumentPage"]),
  },
  created() {
    if (this.client) {
      const params = `?clientId=${this.client._id}`;
      this.fetchDocumentList(params);
    }
  },
  methods: {
    ...documentActions(["fetchDocumentList", "createDocument"]),
    resetForm() {
      this.documentForm = {
        name: "",
        file: [],
      };
    },
    addClientDocument() {
      const { name, file } = this.documentForm;
      let formData = new FormData();
      formData.append("name", name);
      formData.append("clientId", this.client._id);
      if (file.length > 0) {
        formData.append("file", file[0]);
      }
      this.createDocument(formData).then((doc) => {
        if (doc) this.isNewDocument = false;
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_DOCUMENT")) {
      this.$store.registerModule("CLIENT_DOCUMENT", ClientDocumentModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_DOCUMENT");
  },
});
