var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.productSalePage.docs,"options":_vm.options,"server-items-length":_vm.productSalePage.total,"no-data-text":"No products to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.saleProduct.name)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" "+_vm._s(item.unitPrice * item.quantity)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.date,"D/MM/YYYY"))+" ")]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [(item.paymentStatus === 'settled')?_c('v-chip',{attrs:{"color":"#d3f9db"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.paymentStatus))])]):_vm._e(),(item.paymentStatus === 'pending')?_c('v-chip',{attrs:{"color":"#fcf1d0"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.paymentStatus))])]):_vm._e(),(item.paymentStatus === 'cancelled')?_c('v-chip',{attrs:{"color":"#facccc"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.paymentStatus))])]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }